.song {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px 8px 16px;
    cursor: pointer;
}

.song.active {
    background-color: rgba(130, 87, 230, .3);
    border-radius: 5px;
    position: relative;
}

.song.active::after{
    content: '';
    width: 6px;
    height: 60px;
    background-color: rgba(130, 87, 230, .4);
    position: absolute;
    left: 0;
    border-radius: 5px 0px 0px 5px;
}

.song > span {
    display: block;
}

.infos {
    display: flex;
    align-items: center;
}

.titles > span,
.infos > span,
.song > span {
    display: block;
}

.number {
    color: #B2B2B2;
    font-size: 16px;
    margin-right: 20px;
    margin-left: 6px;
    width: 12px;
}

.playIcon {
    margin-right: 26px;
}

.titles {
    margin-left: 16px;
}

.mTitle {
    color: #fff;
    font-size: 16px;
}

.mArtist {
    font-size: 14px;
    color: #B2B2B2;
}

.albumName {
    font-size: 14px;
}

.duration {
    font-size: 15px;
    color: #B2B2B2;
    padding-left: 48px;
}