.home {
    position: relative;
    height: 100vh;
    color: white;
    background-color: #000;
}

.song {
    cursor: pointer;
}

.homeGrid {
    display: grid;
    grid-template-columns: 230px 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    height: 100vh;
}

.rightArea {
    overflow-y: auto;
}

.rightArea::-webkit-scrollbar {
    width: 6px;               /* width of the entire scrollbar */
  }
  
.rightArea::-webkit-scrollbar-track {
background: #000;        /* color of the tracking area */
}

.rightArea::-webkit-scrollbar-thumb {
background-color: #363333;;    /* color of the scroll thumb */
border-radius: 20px;       /* roundness of the scroll thumb */
}

.hidden {
    background-color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999999999999;
}

.hidden > input {
    opacity: 0;
}

@media (min-width: 480px){
    .hidden > input {
        display: none;
    }
}

@media (max-width: 480px){
    .homeGrid {
        grid-template-columns: 1fr;
    }
}