@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

.audioPlayer {
    --primary: #8026D4;
    --secondary: #D289F2;

    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #1D1F3E;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
}

.forwardBackward {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    font-family: monospace;
    font-size: 16px;
    cursor: pointer;
    color: #9FA3C2;
    margin-left: 12px;
    margin-right: 12px;
}

.forwardBackward:hover {
    color: var(--primary);
}

.playPause {
    background: var(--primary);
    border: none;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    font-size: 24px;
    color: var(--secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.play {
    position: relative;
    left: 5px;
}

.currentTime,
.duration {
    font-family: monospace;
    font-size: 14px;
    font-family: 'Rubik', sans-serif;
    min-width: 36px;
}

.currentTime {
    margin-left: 25px;
    margin-right: 8px;
    color: #C4C4C4;
}

.duration {
    margin-left: 12px;
    color: #C4C4C4;
}

  .player {
    background-color: #181818;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 230px;
    height: 101px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
  }

  .songInfo {
    display: flex;
    max-width: 215px;
    min-width: 215px;
  }

  .songInfo > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 18px;
    font-family: 'Rubik', sans-serif;
  }

  .musicTitle {
    font-size: 20px;
    color: #F1F1F1;
  }

  .artistName {
    font-size: 16px;
    color: #646464;
  }

  .controls {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .musicRange {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .musicVolume {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-right: 28px;
  }

  .cursorPointer {
    cursor: pointer;
  }

  .volBar {
    display: none;
  }


.wait {
  display: none;
}

@media (max-width: 480px){
  .player {
    left: 0;
    height: 70px;
    padding-right: 32px;
  }

  .songInfo > img {
    width: 70px;
    height: 70px;
  }

  .musicTitle {
    font-size: 16px;
  }

  .random,
  .back,
  .forward,
  .repeat,
  .musicVolume,
  .musicRange {
    display: none;
  }

  .wait {
    display: block;
    position: absolute;
    top: -12px;
    left: 0;
    right: 0;
    }

  .play,
  .pause {
    width: 40px;
  }
}
