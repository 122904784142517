.container {
    --progress-bar-height: 12px;
    --thumb-width: 15px;
    --thumb-height: 15px;
}

.container {
    position: relative;
    width: 100%;
}

.container::before {
    content: '';
    background-color: #333333;
    width: 99%;
    height: calc(var(--progress-bar-height) - 1px);
    display: block;
    position: absolute;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

.progressBarCover {
    background-color: #8257E6;
    width: 0%;
    height: var(--progress-bar-height);
    display: block;
    position: absolute;
    border-radius: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    user-select: none;
    pointer-events: none;
}

.range {
    -webkit-appearance: none;
    background-color: #333333;
    height: 10px;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    margin: 0 auto;
}

.range::-webkit-slider-thumb {
    width: var(--thumb-width);
    height: var(--thumb-height);
    background: #350f2d;
    border: 1px solid #000000;
    border-radius: 50%;
    cursor: pointer;
    -webkit-appearance: none;
}

.thumb {
    width: var(--thumb-width);
    height: var(--thumb-height);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.753);
    z-index: 3;
    background: #4E28A6;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    transform: translate(0%, -50%);
    pointer-events: none;
    user-select: none;
}

@media (max-width: 480px){
    .container {
        --progress-bar-height: 6px;
        --thumb-width: 9px;
        --thumb-height: 9px;
    }

    .container::before {
        border-radius: 0;
        width: 100%;
    }

    .progressBarCover {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}