.bgScreen {
    background-color: #000;
    color: #646464;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bgForm {
    background-color: #181818;
    padding: 48px 32px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 300px;
    box-shadow: 0px 0px 8px 4px rgba(100, 100, 100, 0.1);
}

.bgForm > div {
    display: flex;
    flex-direction: column;
}

.bgForm > div > input {
    height: 38px;
    margin-top: 8px;
    background-color: #646464;
    color: #fff;
    border: 0;
    outline: none;
    border-radius: 4px;
    padding: 0px 8px 0px 8px;
}

.bgForm > div > input::placeholder {
    color: #A8A8A8;
    font-style: italic;
}

.bgForm > div > span {
    color: #E65757;
    margin-top: 4px;
    font-size: 14px;
}

.bgForm > div > button {
    text-transform: uppercase;
    background-color: #8257E6;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 8px 16px;
    height: 42px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 24px;
    transition: .5s all;
}

.bgForm > div > button:hover {
    background-color: #542EAD;
}

.bgForm > div > button:disabled {
    background: rgba(130, 87, 230, .3);
    color: #8257E6;
    cursor: not-allowed;
  }

.wrongCredentials {
    color: #E65757;
    margin-top: 4px;
    font-size: 14px;
}

.spinner {
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

@media (max-width: 480px){
    .bgForm {
        background-color: transparent;
    }
}