@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.header {
    background: linear-gradient(180deg, #3C3F41 0%, #000000 100%);
    height: 232px;
    padding: 28px 28px 0px 28px;
}

.title {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 500;
    color: #F6F6F6;
    display: block;
    margin-top: 76px;
}

.songsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #232424;
    color: #B6B7B7;
    font-size: 12px;
    margin-top: 26px;
}

.songsHeaderTitle {
    margin-left: 18px;
}

.searchArea {
    position: relative;
    width: 312px;
    margin: 0 auto;
}

.search {
    position: absolute;
    width: 16px;
    right: -20px;
    top: 50%;
    transform: translate(0, -60%);
}

.searchArea > input {
    width: 100%;
    background-color: rgba(130, 87, 230, .1);
    border: 2px solid rgba(130, 87, 230, .2);
    border-radius: 50px;
    padding: 8px 20px;
    outline: none;
    color: #F6F6F6;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
}