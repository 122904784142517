.chat {
    background-color: #181818;
    border-radius: 4px;
    position: absolute;
    z-index: 999;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 24px;
    height: 200px;
    width: 320px;
    padding: 16px 16px 32px 16px;
    display: flex;
    flex-direction: column;
}

.talk {
    width: 100%;
    display: flex;
    gap: 16px;
    padding-top: 16px;
}

.talk > input {
    max-width: 100%;
    flex: 1;
    background-color: #333333;
    color: #f1f1f1;
    padding: 4px 8px;
    border: none;
    outline: none;
    border-radius: 4px;
}

.talk > input::placeholder {
    color: #f1f1f1;
    opacity: .2;
}

.messages {
    overflow: auto;
}

.messages {
    overflow-y: auto;
}

.messages::-webkit-scrollbar {
    width: 6px;               /* width of the entire scrollbar */
  }
  
.messages::-webkit-scrollbar-track {
background: #000;        /* color of the tracking area */
}

.messages::-webkit-scrollbar-thumb {
background-color: #363333;;    /* color of the scroll thumb */
border-radius: 20px;       /* roundness of the scroll thumb */
}

.messages > p > span,
.other > span,
.me > span {
    display: block;
    font-size: 9px;
    margin-top: 3px;
}

.other,
.me {
    padding: 4px 16px;
    font-size: 14px !important;
}

.other > img,
.me > img {
    cursor: pointer;
}

.me {
    text-align: end;
    background-color: #292929;
    width: auto;
}

.talk > button {
    cursor: pointer;
    color: #fff;
    background-color: rgb(130, 87, 230);
    border-radius: 4px;
    border: none;
    outline: none;
}

.emojiArea{
    height: 200px;
    overflow-y: hidden;
    transition: all ease 0.3s;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 92px;
  }

.emoji {
    cursor: pointer;
}

.sendImage {
    cursor: pointer;
}

.sendImage > label {
    cursor: pointer;
}

.sendImage > input[type=file] {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

.imageArea {
    max-width: 50%;
}