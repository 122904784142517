.sidebar {
    background-color: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menu {
    padding: 20px 8px;
}

.menu > button {
    width: 100%;
    height: 40px;
    margin-top: 84px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background-color: #8257E6;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    transition: .5s all;
}

.menu > button:hover {
    background-color: #542EAD;
}

.homeIcon {
    margin-right: 16px;
    height: 20px;
}

.userArea {
    border-top: 1px solid #343538;
    padding: 30px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.userProfile {
    display: flex;
    align-items: center;
    gap: 20px;
}

.userProfile > span {
    color: #AAAEB5;
    font-size: 14px;
    font-weight: 500;
}

.btnMenu {
    cursor: pointer;
}

@media (max-width: 480px){
    .sidebar {
        display: none;
    }
}
